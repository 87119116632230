import React from 'react'
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'
import ButtonWhiteArrow from 'components/elements/Buttons/ButtonWhiteArrow'

const Wrapper = styled.section`
  background: rgb(${(props) => props.theme.color.gradient.contrast.to});
  background: linear-gradient(
    135deg,
    rgba(${(props) => props.theme.color.gradient.contrast.from}, 1) 0%,
    rgba(${(props) => props.theme.color.gradient.contrast.to}, 1) 45%
  );
`

interface BannerCtaProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_BannerCta
  location?: any
}

const BannerCta: React.FC<BannerCtaProps> = ({ fields }) => (
  <Wrapper>
    <div className="container">
      <div className="row align-items-center py-5">
        <div className="col-md-9">
          <ParseContent content={fields?.description} />
        </div>
        <div className="col-md-3 d-flex justify-content-lg-end justify-content-center mt-5 mt-md-0">
          <ButtonWhiteArrow to={fields?.link?.url || '#'}>
            {fields?.link?.title}
          </ButtonWhiteArrow>
        </div>
      </div>
    </div>
  </Wrapper>
)

export default BannerCta
